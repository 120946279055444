
import Vue from 'vue'
import store from '@/store'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: "Home",
  data() {
    return {
      valid: true,
      loading: false,
      clientName: '',
      ownerName: '',
      rules: {
        required: value => !!value || this.$t('home.label_required'),
      }
    }
  },
  mounted () {

    console.log('Home.vue', JSON.stringify(this.$route.query))

    if (this.$route.query.accessToken && this.$route.query.refreshToken
      && this.$route.query.username && this.$route.query.userGroup
      && this.$route.query.clientId && this.$route.query.clientName) {

      this.saveAccessToken(this.$route.query.accessToken)

      this.saveRefreshToken(this.$route.query.refreshToken)

      this.saveUsername(this.$route.query.username)

      this.pickUserGroup(this.$route.query.userGroup)

      this.pickClient({
        clientId: this.$route.query.clientId,
        clientName: this.$route.query.clientName,
      })      
    }
    
    if (this.username) {
      if (this.userGroup == 'owner') {
        this.$router.push({name: 'Clients'})
      } else if(this.userGroup == 'tenant') {
        this.$router.push({name: 'Clients'})
      } else if(this.userGroup == 'user') {
        this.$router.push({name: 'Devices'})
      } else if(this.userGroup == 'guest') {
        this.$router.push({name: 'Guest'})
      }
    }
  },
  computed: {
    ...mapState({
      username: (state: any) => state.auth.username,
      userGroup: (state: any) => state.auth.userGroup
    }),
  },
  methods: {

    async loginClicked(userGroup: string) {

      if (this.$refs.form1.validate()) {

        this.loading = true

        await this.login({clientName: this.clientName, userGroup: userGroup})
     
      }

    },
    async ownerLoginClicked(userGroup: string) {

      if (this.$refs.form2.validate()) {

        this.loading = true

        await this.login({clientName: this.ownerName, userGroup: userGroup})
     
      }

    },
    ...mapActions({
      login: 'auth/login',
      saveAccessToken: 'auth/saveAccessToken',
      saveRefreshToken: 'auth/saveRefreshToken',
      saveUsername: 'auth/saveUsername',
      pickClient: 'auth/pickClient',
      pickUserGroup: 'auth/pickUserGroup',
    })
  },
  // async beforeRouteEnter (to, from, next) {
  //   store.dispatch('auth/clearToken')

  //   store.dispatch('clearRootStates', {}, {root: true})

  //   next()
  // },
};
